import * as _ from 'modules/util'
import { unit } from './space'
import { rem } from './units'

export const absoluteCenter = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

export const absoluteCenterX = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
}

export const baselineOffset = {
  position: 'relative',
  bottom: rem(2),
}

export const negativeBaselineOffset = {
  position: 'relative',
  bottom: rem(-2),
}

export const bodyFont = {
  fontFamily: 'source-sans-pro',
}

export const borderRadius = 4

export const breakWords = {
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: ['break-all', 'break-word'],
  hyphens: 'auto',
}

export const arrow = ({ position = 'top', color = 'white', size = 4, ...props } = {}) => [
  {
    position: 'absolute',
    border: 'solid transparent',
    content: '""',
    top: 'auto',
    left: 'auto',
    height: 0,
    width: 0,
    margin: 0,
    borderColor: 'transparent',
    borderWidth: rem(size),
    pointerEvents: 'none',
  },
  _.when(position === 'top', {
    bottom: '100%',
    borderBottomColor: color,
    marginLeft: rem(size * -1),
    left: '50%',
  }),
  _.when(position === 'right', {
    left: '100%',
    borderLeftColor: color,
    marginTop: rem(size * -1),
    top: '50%',
  }),
  _.when(position === 'bottom', {
    top: '100%',
    borderTopColor: color,
    marginLeft: rem(size * -1),
    left: '50%',
  }),
  _.when(position === 'left', {
    right: '100%',
    borderRightColor: color,
    marginTop: rem(size * -1),
    top: '50%',
  }),
  props,
]

export const center = {
  marginLeft: 'auto',
  marginRight: 'auto',
}

export const clearfix = {
  '&::after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },
}

export const container = {
  on: (width, styles) => ({
    [`@container (width >= ${rem(width)})`]: styles,
  }),
  below: (width, styles) => ({
    [`@container (width < ${rem(width)})`]: styles,
  }),
}

export const fullWidth = {
  left: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  position: 'relative',
  right: '50%',
  width: '100vw',
}

export const flexibleGrid = ({ gap = unit(2), maxColumns, minWidth }) => {
  const totalGapWidth = `calc(${maxColumns - 1} * ${gap})`
  const maxWidth = `calc((100% - ${totalGapWidth}) / ${maxColumns})`
  return {
    display: 'grid',
    gap,
    gridTemplateColumns: `repeat(auto-fill, minmax(max(${minWidth}, ${maxWidth}), 1fr))`,
    alignItems: 'start',
  }
}

export const flexWrap = styles => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: unit(1),
  ...styles,
})

export const grid = (template, styles) => ({
  display: 'grid',
  gridTemplateColumns: template,
  gap: unit(1),
  alignItems: 'start',
  ...styles,
})

export const hideFromPrint = { '@media print': { display: 'none' } }

export const insetBorder = ({ color = 'currentColor', width = 1 } = {}) => ({
  boxShadow: `inset 0 0 0 ${width}px ${color}`,
})

export const rgb = hex =>
  `${parseInt(hex.substring(1, 3), 16)}, ${parseInt(hex.substring(3, 5), 16)}, ${parseInt(
    hex.substring(5),
    16
  )}`

export const rgba = (hex, alpha) => `rgba(${rgb(hex)}, ${alpha})`

export const shadow = `0 1px 4px 0 rgba(13, 24, 28, 0.25)`

export const textTruncate = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export const visuallyHidden = {
  position: 'absolute',
  height: 1,
  width: 1,
  overflow: 'hidden',
  clip: 'rect(1px, 1px, 1px, 1px)',
}
